import * as React from "react"
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../../../images/vectors/undraw_Analytics_re_dkf8.svg"
import measure_realtime from '../../../images/vectors/undraw_Real_time_sync_re_nky7.svg'
import server_cluster from '../../../images/vectors/undraw_server_cluster_jwwq.svg'
import setup_analytics from '../../../images/vectors/undraw_setup_analytics_re_foim.svg'
import PrivacySection from "../../../components/PrivacySection";
import PricingSection from "../../../components/PricingSection";
import CoreConcept from "../../../components/CoreConcept";
import WebAnalyticsFirstpartyWorkflow from "../../../components/workflows/WebAnalyticsFirstpartyWorkflow";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Customer Analytics for Marketers and Developers</title>
                <meta name="description" content="Keep cookies from your own domain, collect events in any browser, and move everything to your warehouse." />
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>

            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <span className="text-gray-700">
                                        Solutions
                                    </span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Analytics
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>

            <section className="py-8 py-md-11 border-bottom">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-1 fw-bold aos-init aos-animate" data-aos="fade-up"
                                data-aos-delay="50">Your first-party web analytics solution
                            </h1>
                            <p className="lead text-muted mb-6 aos-init aos-animate" data-aos="fade-up"
                               data-aos-delay="100">
                                Use Firstparty to collect proprietary customer analytics. Serve first-party cookies from your own domain, collect every event without being blocked by ad
                                blockers, and keep customer information secure in your warehouse.
                            </p>
                            <p className="mb-7 mb-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <a className="btn btn-primary shadow lift" href="https://app.firstpartyhq.com/authentication/signup">
                                    Get Started with 1 Million Free Events<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-14 bg-gray-800">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-5 align-self-center">
                            <h3 className="text-gray-100">Get Started with a Workflow</h3>
                            <p className="text-gray-400">
                                Workflows guide you through configuring multiple Firstparty components to help achieve
                                an objective, and can help you solve marketing problems like analytics, attribution,
                                and audience creation in just a few clicks.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/workflows/">Explore All Workflows <i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                        <div className="col-12 col-md-8 mb-5">
                            <WebAnalyticsFirstpartyWorkflow />
                        </div>
                    </div>
                </div>
            </section>

            <div className="section bg-black pt-10 pt-md-10 pb-8 pb-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col order-md-2 text-center">

                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0"
                                 src={analytics} alt="..." />

                        </div>
                        <div className="col-12 col-md-7 order-md-1">

                            <h2 className="text-white">
                                Own all of your web analytics data
                            </h2>

                            <div className="lead text-muted-80 mb-0">
                                Firstparty moves your web analytics to a warehouse you control, enabling you
                                to activate that data on your terms.
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1"
                                               href="#!">
                                                <span>1</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        First-party Cookies
                                    </h3>

                                    <p className="text-muted-80 mb-6 mb-md-0">
                                        Bypass most ad blockers and see nearly 100% of your traffic, without sacrificing
                                        privacy protection for your users.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>2</span>
                                            </a>

                                        </div>
                                        <div className="col">

                                            <hr className="d-none d-md-block bg-gray-900-50 me-n7" />

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Collect Events Anywhere
                                    </h3>

                                    <p className="text-muted-80 mb-6 mb-md-0">
                                        Collect Events from your website, your existing data warehouse, or any server
                                        or service that can make an HTTP request.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">

                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">

                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>3</span>
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">

                                    <h3 className="text-white">
                                        Warehouse Connectors
                                    </h3>

                                    <p className="text-muted-80 mb-0">
                                        Firstparty moves your web analytics to a warehouse you control, enabling you
                                        to activate that data on your terms. Support for Postgres, S3, Redshift, and
                                        BigQuery out of the box.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x shape-flip-x text-black">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="pt-10 pt-md-15 pb-10 pb-md-14 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-10 text-center">
                            <h6 className="text-uppercase text-primary">
                                Audiences
                            </h6>
                            <h2>Build Audiences From Website Analytics</h2>
                            <p className="fs-lg text-gray-700 pb-5">
                                Create Segments of Events and build Audiences of individuals from web activity combined with data from other marketing tools, CRM, internal databases, and more.
                            </p>
                            <a href="/solutions/audiences/" className="btn btn-primary-soft">Explore Audiences</a>
                        </div>
                    </div>
                </div>
            </section>

            <CoreConcept
                title="Events represent actions performed by your website visitor or customer."
                documentation_link="/docs/items/events/"
                api_link="/docs/api/reference/#tag/Events"
            >
                <p className="text-gray-400">
                    Events are the central measurement of who, how, and when your website or application is being used.
                    Events may be collected using Firstparty.js, the Firstparty HTTP API, or a Warehouse Source, and can
                    be sent to an unlimited number of Warehouses of your choice.
                </p>
            </CoreConcept>

            <section className="pb-8 pb-md-11">
                <Container>
                    <Row className="justify-content-between align-items-center">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={measure_realtime} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1 pt-md-12">
                            <h6 className="text-uppercase text-primary">
                                Accurate measurement
                            </h6>
                            <h2>
                                Increase data quantity & quality
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Firstparty serves cookies and its tracking library from your website's domain.
                                This means browsers and ad blockers won't prevent you from collecting the data you need
                                to run your business.
                            </p>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary">
                                            Simple DNS setup
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-lg-0">
                                            Cookies stay valid
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex">
                                        <span className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                        <i className="fe fe-check"></i>
                                    </span>
                                        <p className="text-primary">
                                            Mitigate ad blockers
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft me-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p className="text-primary mb-0">
                                            Supports all browsers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 img-skewed img-skewed-end">
                            <div className="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          data-typed="{&quot;backSpeed&quot;:2, &quot;strings&quot;: [&quot;>&nbsp;$&nbsp;npm&nbsp;install<br/><span class=\&quot;text-success\&quot;>Everything&nbsp;is&nbsp;installed</span><br/><br/>>&nbsp;$&nbsp;npm start<br/><span class=\&quot;text-success\&quot;>scss&nbsp;watching</span><br/><span class=\&quot;text-success\&quot;>LiveReload&nbsp;started</span><br/><span class=\&quot;text-success\&quot;>Opening&nbsp;localhost:8080</span><br/><br/>>&nbsp;$&nbsp;that’s&nbsp;it<br/><span class=\&quot;text-success\&quot;>Yup,&nbsp;that’s&nbsp;it.</span>&quot;]}"
                                          style={{minHeight: "183px"}}>
                                        <span className="text-success">// Track pageviews with one line of Javascript</span>
                                        <br /><br />
                                        firstparty.page()
                                        <br /><br />
                                        <br /><br />
                                        <span className="text-success">// Easily track custom events</span>
                                        <br /><br />
                                        firstparty.track('Form Completed', &#123;
                                        <br />
                                        &nbsp;&nbsp;email: 'customer@gmail.com'
                                        <br />
                                        &#125;)
                                        <br />
                                    </code>
                                    <span className="typed-cursor" aria-hidden="true">|</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h6 className="text-uppercase text-primary">
                                Accurate measurement
                            </h6>
                            <h2>Collect any event with ease</h2>
                            <p className="fs-lg text-gray-700">
                                Firstparty.js is an easy to install Javascript snippet that makes it easy to track
                                pageviews, form completions, user activity, or just about anything else - <span
                                className="text-primary"> from your website and app.</span>
                            </p>
                            <a className="fw-bold text-decoration-none" href="#!">
                                See our easy installation instructions <i className="fe fe-arrow-right ms-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={server_cluster} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h6 className="text-uppercase text-primary">
                                Portable marketing analytics
                            </h6>
                            <h2>
                                Copy everything <br />to your data warehouse
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Your analytics data should be portable. Firstparty copies every event you generate to
                                your data warehouse(s) on your schedule. Not ready to select a warehouse? We'll store it
                                for you and migrate it when you're ready.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Postgres, S3, Redshift, BigQuery, and more coming soon
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Sync as often as every hour
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11 bg-light">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0"
                                 src={setup_analytics} alt="..." />

                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h6 className="text-uppercase text-primary">
                                Accurate visitor measurement
                            </h6>
                            <h2>Measure activity and build custom reports</h2>
                            <p className="fs-lg text-gray-700">
                                Answer questions from "where are my users visiting from geographically?" to "how many
                                visitors have looked at our pricing page?" directly within Firstparty.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-light">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={server_cluster} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h6 className="text-uppercase text-primary">
                                Maintain data ownership
                            </h6>
                            <h2>
                                Unlimited Users and Warehouses
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                Anyone on your team may have access to Firstparty - whether they're marketers, engineers,
                                executives, or anyone else.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Add as many Users as you'd like
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            <i className="fe fe-check"></i>
                                        </div>
                                        <p>
                                            Sync to an unlimited number of Warehouses
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="position-relative mt-0 mt-md-n10">
                <div className="shape shape-bottom shape-fluid-x text-gray-800">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0h720v250H0V125h720z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <PrivacySection />

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage

import React from 'react'
import { Link } from 'gatsby'
import isolated from '../images/vectors/undraw_privacy_protection_nlwy.svg'

export default (props) => (
        <section className="bg-gray-800 pt-10 pb-10">
            <div className="container">
                <div className="row mb-6 justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src={isolated} className="img-fluid" />
                    </div>
                </div>
                <div className="row mb-5 text-center">
                    <div className="col-12 col-md-8 offset-md-2 mb-5 text-white">
                        <h2 className="mb-0">Reduce Customer Privacy Infringement</h2>
                        <h3 className="text-light">Firstparty keeps cookies on your domain, so your customers are never tracked once they leave your site.</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-5">
                        <div className="card bg-gray-900">
                            <div className="card-body">
                                <p className="text-white text-center">Safer (first-party) cookies</p>
                                <p className="text-gray-400 text-center">
                                    By restricting cookies to your domain and never sharing them with a third-party,
                                    your website visitors can't be tracked across the internet with Firstparty data.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6 mb-5">
                        <div className="card bg-gray-900">
                            <div className="card-body">
                                <p className="text-white text-center">We Never Share Data</p>
                                <p className="text-gray-400 text-center">
                                    Firstparty will never share your data with anyone but who you tell us to. Want to
                                    run completely isolated from third-party marketing systems? No problem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {Boolean(props.signup) ?
                <div className="row mt-5">
                    <div className="col text-center">
                        <Link to="/get-started" className="btn btn-primary-desat mb-6 mb-md-0 lift">
                            Get Started for Free<i className="fe fe-arrow-right ml-3"></i>
                        </Link>
                    </div>
                </div> : ""}
            </div>
        </section>
)